@font-face {
  font-family: "Gotham-Medium";
  src: url(./components/fonts/Gotham-Medium.otf);
}

@font-face {
  font-family: "Gotham-Book";
  src: url(./components/fonts/Gotham-Book.otf);
}

@font-face {
  font-family: "Times";
  src: url(./components/fonts/TimesLTStd-Roman.otf);
}

/* @font-face {
  font-family: "tllmedium";
  src: url(./components/fonts/tll-webfont.woff) format("woff"),
       url(./components/fonts/tll-webfont.svg) format("svg"),
       url(./components/fonts/tll-webfont.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+20-21, U+3F, U+41-5A, U+61-7A;

} */
/* @font-face {
  font-family: 'Times-Bold';
  src: url(./components/fonts/TimesLTStd-Bold.otf);
} */
html {
  scroll-behavior: smooth;
}
/* *[id]:before { 
  display: block; 
  content: " "; 
  margin-top: -120px; 
  height: 120px; 
  visibility: hidden; 
} */
body {
  margin: 0;
  color: #121212;
  font-family: "Gotham-Book", "tllmedium", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.scrollbar-hidden .MuiTabs-scroller.MuiTabs-fixed::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden .MuiTabs-scroller.MuiTabs-fixed {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
.scrollbar-hidden .MuiTabs-scroller.MuiTabs-fixed {
  overflow-x: auto !important;
  padding-left: 20px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.tll-line-number {
  position: absolute;
  left: 0;
}
.article-data {
  position: relative;
  padding-left: 170px;
  word-wrap: break-word;
  font-size: 16px !important;
  line-height: 30px !important;
}
.entry-lemma a {
  line-height: 30px;
}
.article-data a,
.others-data a,
.main-section a,
.insidedata a {
  color: #007596;
}
.article-data a:hover,
.others-data a:hover,
.main-section a:hover,
.insidedata a:hover {
  color: #80aebb;
}
.article-data a.nolink,
.others-data a.nolink,
.main-section a.nolink,
.insidedata a.nolink {
  text-decoration: none !important;
  color: inherit !important;
  pointer-events: none !important;
}
.level1 {
  padding-left: 25px;
}
.level2 {
  padding-left: 50px;
}
.level3 {
  padding-left: 75px;
}
.level4 {
  padding-left: 100px;
}
.level5 {
  padding-left: 125px;
}
.d-flex {
  display: flex;
}
a {
  color: inherit;
  cursor: pointer;
}
.fw-400 {
  font-weight: 400 !important;
}

.addthis_bar {
  display: none;
}
a.addthis_button_compact {
  color: inherit;
}

a.addthis_button_compact:hover {
  text-decoration: none;
  color: inherit;
}
button {
  text-transform: capitalize !important;
  font-family: Gotham-Medium, sans-serif !important;
  border-radius: 0 !important;
}

/*
* ------ DropDown Start
*/
.select-box {
  position: relative;
  width: 155px;
  height: 72px;
}
/* @media (min-width: 768px) {
    .select-box {
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .select-box {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .select-box {
      width: 30%;
    }
  } */
.select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
  height: 100%;
  display: flex;
  align-items: center;
}
.select-box__current:hover,
.select-box__current:hover .select-title {
  background-color: #007596;
  color: #fff !important;
}
.select-box__current:focus + .select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}
.select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}
.select-box__current:focus .select-box__icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}
.select-box__value {
  display: -webkit-box;
  display: flex;
}
.select-box__input {
  display: none;
}
.select-box__input:checked + .select-box__input-text {
  display: flex;
  flex-direction: column;
}
.select-title {
  font-size: 14px !important;
  color: #3c3c3c !important;
  line-height: 19px;
  font-family: "Gotham-Medium";
  margin-bottom: 2px;
}
.select-value {
  font-family: "Gotham-Book";
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding-left: 28px;
  min-width: 150px;
}
.select-box__list::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 40px solid #007596;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 15px;
}
.select-box__list {
  position: absolute;
  top: 85px;
  padding: 0;
  min-width: 155px;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  z-index: 3;
}
.select-box__option {
  display: block;
  padding: 15px;
  background-color: #007596;
  color: #fff;
  margin: 0;
}
.select-box__option:hover,
.select-box__option:focus {
  color: #007596;
  background-color: #ffffff;
}
.preamomenda {
  text-decoration-line: underline !important;
  color: #005d78 !important;
}
@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
/*
  * ------ DropDown End
  */
.searchbtn {
  padding: 7px !important;
}
.search-btn {
  font-family: "Gotham-Medium" !important;
  width: 120px !important;
  height: 56px !important;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section {
  padding-right: 90px !important;
  padding-left: 90px !important;
}
.section-y {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}
.main {
  padding-bottom: 520px !important;
}
.body-container {
  min-height: 100% !important;
  position: relative !important;
  max-width: 1980px !important;
  margin: 0 auto !important;
}
.mobile-search-btn {
  margin-top: 20px;
  width: 100%;
  font-family: "Gotham-Medium" !important;
}
/* Cite Modal */
.cite-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cite-container button {
  text-transform: initial !important;
}

.cite-modal {
  max-width: 500px;
  background: #ffffff;
  position: relative;
}

.modal-header {
  height: 60px;
  background-color: #005d78;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.modal-header h2 {
  font-size: 24px !important;
}
.modal-close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
.modal-body {
  padding: 30px 40px;
}
.download-modal {
  margin: 20px 0;
  display: flex;
}
.select-modal {
  background-color: #ffffff;
  margin-right: 20px;
}
.select-modal select {
  padding-left: 18px;
  background-color: #ffffff;
}
.modal-divider {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.formatbtns {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.formatbtn {
  flex: 1;
}
.formatbtn:not(:last-child) {
  margin-right: 21px;
}
/* Cite Modal ends */
.selectedbtn {
  background-color: #007596 !important;
}
.selectedbtn button {
  color: #ffffff !important;
}

.sticky-find {
  position: sticky;
  top: 70px;
  background: #f6f6f8;
  z-index: 999;
}
@media (min-width: 960px) {
  .sticky-find {
    padding-top: 5px;
    padding-bottom: 5px;
    top: 115px;
  }
  .citdata table.citTable tr td:first-child {
    width: 20vw;
  }
  .main {
    padding-bottom: 110px !important;
  }
}
@media (max-width: 960px) {
  .section {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .citdata table.citTable tr td:first-child {
    width: 40vw;
  }
  .section-y {
    padding-top: 15px !important;
    padding-bottom: 60px !important;
  }
  .mobile-border {
    border: 0.5px solid #717173 !important;
  }
  .modal-body {
    padding: 30px;
  }
}

cit:target {
  background: var(--highlight-color);
}

mark {
  background: var(--highlight-color);
}

mark.current {
  color: var(--highlight-color);
  background: #121212;
}

mark.permanentHighlight,
permanentmark {
  background: var(--highlight-color);
}
.number {
  font-family: "Gotham-Book" !important;
  font-size: 16px;
  line-height: 30px;
  margin-right: 13px;
  color: #717173;
}
.center-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

select {
  font-family: "Gotham-Medium" !important;
  font-size: 16px !important;
  color: #000000 !important;
  text-overflow: ellipsis;
}
.btnborder {
  border: 0.5px solid #a4a4a4 !important;
  margin-left: 10px !important;
  width: 45px !important;
  height: 45px !important;
}

.tll {
  font-family: "Merriweather", "tllmedium" !important;
}

/* XML */

/* body title {
  display:  inline;
} */

sense title {
  display: inline;
}

*[rend="bold"] {
  font-weight: 700;
}

*[rend="ital"] {
  font-style: italic;
}

*[rend="boldital"] {
  font-weight: bold;
  font-style: italic;
}

*[style="exp"] {
  letter-spacing: 2px;
}
/* bibl{
  display: inline-flex;
  align-items: baseline;
  white-space:nowrap;
    overflow:hidden;
} */

/* *[style="msc"] {
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  max-width: min-content;
}
.entry-lemma *[style="msc"] {
  white-space: pre;
}
*[style="msc"]::first-letter {
  font-size: 15px!important;
} */
/* bibl author{
  margin-right: -4px;
} */

/* bibl{
  letter-spacing: -0.31em;
}
bibl *{
  letter-spacing: normal;
} */
*[style="sup"] {
  vertical-align: super;
  font-size: 12px;
}

*[style="sub"] {
  vertical-align: sub;
  font-size: 12px;
}

*[style="sc"] {
  text-transform: uppercase;
  font-size: 12px;
}

sense {
  display: block;
  margin-top: 1rem;
}

sense > sense,
sense > .sense,
.sense > sense,
.sense-cit > sense
 {
  padding-left: 25px;
}

.sense {
  display: inline-block;
}

.sense-cit {
  display: inline-block;
}

lbl {
  font-weight: 700;
}

note[style="exp"] {
  text-emphasis: 1;
}

.insidedata form {
  display: inline;
}
/* #romance form[type="roman"] , #criticism form[type="crit"] {
  display: block;
}
#etymology etym{
  display: block;
}
#appendix dictscrap {
  display: block;
} */
/* etym{
  letter-spacing: -0.31em;
}*/
etym note {
  display: inline;
}

.atag {
  color: #005d78;
}
.fs-16 {
  font-size: 16px !important;
}

.citdata a {
  /* pointer-events: none;
color: inherit !important; */
}

table.citTable tr:nth-of-type(2n + 1) {
  background-color: #f6f6f8;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

table.citTable tr:nth-of-type(2n) {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

table.citTable td {
  padding: 16px;
}

table.citTable {
  min-width: 100%;
}
table.citTable tbody {
  font-size: 14px;
  text-align: left;
  font-weight: 400;
}
table.citTable.indexTable tr:nth-of-type(2n + 1) {
  background-color: #ddcfcf0a;
}
table.citTable.indexTable .tablehead {
  background-color: #f6f6f8 !important;
}
table.citTable.indexTable .insidedata {
  font-size: 14px;
  font-weight: 400 !important;
}
table.indexTable tr td:first-child {
  white-space: pre;
}
.text-unset {
  font-size: 18px !important;
  line-height: 30px !important;
  text-transform: unset !important;
}

/* .entry-lemma .line-number::before {
  display: block;
  content: ' ';
} */

/* .entry-lemma .line-number {
  display: inline-block;
  margin: 0;
} */

span.msc {
  font-size: 16px !important;
}

.font-weight-bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

*[style="msc"] {
  text-transform: uppercase;
  font-size: 12px;
  /* max-width: min-content;
  white-space: pre; */
}

/* .citdata *[style="msc"] {
  display: inline-block;
  margin-right: -4px;
}

.citdata *[style="msc"]::first-letter {
  font-size: 16px !important;
} */

.preamomenda {
  margin-bottom: 5px;
  line-height: 30px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.mobile-add-row {
  margin-bottom: 20px;
}
.addrow {
  padding-right: 20px !important;
  padding-left: 20px !important;
  height: 55px;
}
.addrow .MuiButton-label {
  font-size: 16px !important;
  line-height: 30px !important;
}
.check-list {
  list-style-image: url(./checklist.svg);
  margin-left: 15px;
}
.dash-list {
  list-style-image: url(./dashlist.svg);
  margin-left: 15px;
}
.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.MuiAccordion-root.Mui-expanded::before {
  opacity: 1 !important;
}
.MuiAccordion-root.Mui-expanded + .MuiAccordion-root::before {
  display: block !important;
}

body {
  --highlight-color: #fdff00;
}

highlight {
  background: var(--highlight-color);
}

span.highlight-body:not(:last-child)::after {
  content: "...";
  padding-left: 10px;
  padding-right: 10px;
}

.cc-privacy-btn {
  margin-right: 10px;
}

/** meta dl dt **/

dl.inline {
  text-align: start;
  font-family: "Merriweather";
}

dl.inline dd {
  display: inline;
  margin: 0;
  margin-left: 10px;
  font-weight: 400;
}
dl.inline dd:after {
  display: block;
  content: "";
  height: 5px;
}
dl.inline dt:not(.dt-title) {
  display: inline-block;
}
dl.inline dt:not(.dt-title) {
  font-weight: 700;
  font-family: "Merriweather";
}

dl.inline dt:not(.dt-title):not(.no-seperator)::after {
  content: ":";
}

dl.inline dt.no-colon::after {
  content: none !important;
}

dl.inline {
  font-size: 16px;
}

/* .line-number {
  
}

.line-number span {
  background: rgba(0, 93, 120, 0.25);
  font-weight: 700;
  color: #005D78;
  padding: 5px;
  transition: all 0.4s,
} */

button.newButton,
button.newButton:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #005d78;
  background: #e5eff1;
  border: 1px solid #005d78;
  padding: 10px 15px;
}

*[xml\:id]::before {
  /* display: block;
  content: " "; */
}

.popup-option {
  color: white !important;
}

.modal-body button {
  height: 50px;
  background: #005d78;
}
.formatinfo {
  margin-top: 10px;
}

button.rounded {
  border-radius: 50% !important;
}

entry h3 {
  margin-top: 30px;
  font-family: "Merriweather" !important;
}

/*  Spacing before after known sections */

entry formcustom[type="lemma"],
entry etym,
entry formcustom[type="crit"],
entry formcustom[type="rest"],
entry formcustom[type="roman"],
entry def,
enrty dictScrap {
  margin-bottom: 20px;
  display: block;
}

/*  Spacing before after known sections */

#find:not(:placeholder-shown) {
  border-color: green;
}

lb:before {
  display: block;
  content: " ";
}

#ccc-content button {
  text-transform: initial !important;
}

/* safari scroll padding top alternative */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    :target {
      padding-top: 110px;
      margin-top: -110px;
    }
    @media (max-width: 960px) {
      :target {
        padding-top: 125px;
        margin-top: -125px;
      }
    }
    mark.current::before,
    mark.first-highlight::before {
      display: inline-block;
      padding-top: 150px;
      margin-top: -150px;
      content: "";
    }
  }
}

.showLineNumbers .original-xmlid::before {
  background: rgba(0, 93, 120, 0.25);
  font-weight: 700;
  color: #005d78;
  padding: 5px;
  content: attr(line-number);
  text-emphasis: unset;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  font-size: 16px;
  vertical-align: unset;
  margin: 0 5px;
}
.hideLineNumbers .original-xmlid::before {
  content: none;
  width: 0px;
}

row {
  display: flex;
  border-right: 2px solid #e0e0e0;
  border-left: 2px solid #e0e0e0;
}

cell {
  width: 10vw;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  cell {
    width: 30vw;
  }
}

row:nth-child(1) {
  margin-top: 10px;
}

row:nth-of-type(2n + 1) {
  background-color: #f6f6f8;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

row:nth-of-type(2n) {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

tr {
  border-right: 2px solid #e0e0e0;
  border-left: 2px solid #e0e0e0;
}

td {
  padding-left: 15px;
}

tr:nth-of-type(2n + 1) {
  background-color: #f6f6f8;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

tr:nth-of-type(2n) {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

table tbody tr td row:first-of-type {
  margin-top: 20px;
}

table tbody tr td row:last-of-type {
  margin-bottom: 20px;
}

table {
  width: 100%;
}

/* tr:nth-of-type(n+3) td{
  border-right : 1px solid black; 
} */

/* WordPress content regular table style */

.is-style-regular tr td {
  padding: 0;
}

.is-style-regular tr:nth-of-type(2n + 1) {
  background-color: #fff;
}

.is-style-regular a {
  font-weight: bold;
}