.container h2 {
    font-size: 1.7rem;
    letter-spacing: 0.1rem;
    margin: 0 0 2rem;
}

.container p, .container li  {
    margin: 0 0 1.9rem;
    font-family: "Times","serif";
    font-size: 1.3rem;
    line-height: 1.5;
}

.container li {
    margin-bottom: 1.5rem;
    margin-left: 1rem;
}

.container a {
    color: #005d78;
}

.container video {
    width: 100%;
    height: auto;
}

.container table tr {
    height: 3rem;
}